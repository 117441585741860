import React, { Component } from "react";
import "./BlogPostComponents.scss";

class Alert extends Component {
  render() {
    return (
      <div className="bpstAlert">
        <i />
        {this.props.children}
      </div>
    );
  }
}

export default Alert;
