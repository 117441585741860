import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO';
import { getRandomNumber, toKebabCase } from '../../utils/common';
import InternalLink from '../../components/Links/InternalLink';
import InternalLinkRight from '../../components/Links/InternalLinkRight';
import Heading from '../../components/BlogPost/Heading';
import SubHeading from '../../components/BlogPost/SubHeading';
import SubSubHeading from '../../components/BlogPost/SubSubHeading';
import ListItem from '../../components/BlogPost/ListItem';
import Blockquote from '../../components/BlogPost/Blockquote';
import Alert from '../../components/BlogPost/Alert';
import AlertInfo from '../../components/BlogPost/InfoAlert';
import ExternalLink from '../../components/BlogPost/ExternalLink';
import Code from '../../components/BlogPost/Code';
import { preToCodeBlock } from 'mdx-utils';
import './BlogPost.scss';
import styles from './BlogPost.module.scss';
import ImageWrapper from '../../components/BlogPost/ImageWrapper';
import { PROFILE_IMAGE_URL } from '../../utils/constants';

//TODO : Add Keywords, URL & image to the SEO component

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx;
  const { previous, next } = pageContext;
  const postKeywords = post.frontmatter
    ? post.frontmatter.keywords.join(', ')
    : '';

  const components = {
    h1: Heading,
    h2: SubHeading,
    h3: SubSubHeading,
    li: ListItem,
    blockquote: Blockquote,
    pre: (preProps) => {
      const props = preToCodeBlock(preProps);
      // if there's a codeString and some props, we passed the test
      if (props) {
        return <Code {...props} />;
      }
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />;
    },
    Alert,
    ExtLink: ExternalLink,
    a: ExternalLink,
    AlertInfo,
    ImageWrapper,
  };

  const getProfileImageUrl = () => {
    const id = getRandomNumber({ min: 1, max: 15 });

    return PROFILE_IMAGE_URL(id);
  };

  const renderOutsideContainer = () => (
    <>
      <div className={styles.topDiv} />
      <div className={styles.blogHeroContainer}>
        <div className={styles.blogHero}>
          <div className="heroBlogTags">
            {post.frontmatter.heroTags.map((herotag, indexx) => (
              <Link
                to={`/tags/${toKebabCase(herotag)}`}
                title={`Explore all posts tagged with "${herotag}"`}
                key={indexx}
              >
                <span className={'heroBlogTag heroBlogTag1'} key={indexx}>
                  {herotag}
                </span>
              </Link>
            ))}
          </div>

          <div className="heroBlogTitle">
            <h1>{post.frontmatter.title}</h1>
          </div>

          <div className="heroAuthorRow">
            <div className={'heroAuthorName'}>By Raj Rajhans -</div>

            <div className="heroDate">{post.frontmatter.date}</div>
          </div>

          <div className="heroReadingTime">
            {post.frontmatter.readingTime} minute read
          </div>
        </div>
      </div>
      <div className={styles.blogContentWrapper}>
        <div className={styles.blogContent}>
          <div className="blogText">
            <MDXProvider components={components}>
              <MDXRenderer>{post.body}</MDXRenderer>
            </MDXProvider>
          </div>

          <div className="blogprevnext">
            <div className="blogPrev">
              {previous ? (
                <InternalLink
                  link={previous.fields.slug}
                  title={previous.frontmatter.title}
                >
                  <div className={'blogPrevCntnr'}>
                    <svg
                      className={'blogPrevSVG'}
                      stroke="#3355ff"
                      fill="#3355ff"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      height="1em"
                      width="1em"
                    >
                      <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                    </svg>
                    {previous.frontmatter.title}
                  </div>
                </InternalLink>
              ) : null}
            </div>

            <div className="blogNext">
              {next ? (
                <InternalLinkRight
                  link={next.fields.slug}
                  title={next.frontmatter.title}
                >
                  <div className={'blogNextCntnr'}>
                    <svg
                      className={'blogNextSVG'}
                      stroke="#3355ff"
                      fill="#3355ff"
                      stroke-width="0"
                      viewBox="0 0 448 512"
                      height="1em"
                      width="1em"
                    >
                      <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                    </svg>
                    {next.frontmatter.title}
                  </div>
                </InternalLinkRight>
              ) : null}
            </div>
          </div>

          <div className="blogBottomRow">
            <div className="blogBtmAthrRow">
              <div className="blogBtmAthrImg">
                <img src={getProfileImageUrl()} alt={'raj-rajhans'} />
              </div>

              <div className="blogBtmAthrTxt">
                <div className="blogBtmAthrTxtName">
                  <h2>Raj Rajhans</h2>
                </div>

                <div className="blogBtmAthrBio">Product Engineer @ invideo</div>
              </div>
            </div>

            <div className={styles.blogBtmTags}>
              <div className={styles.tagsText}>Tags</div>
              <div className={styles.blogBtmTagsCntnr}>
                {post.frontmatter.tags.map((tag, index) => (
                  <Link
                    to={`/tags/${toKebabCase(tag)}`}
                    title={`Explore all posts tagged with "${tag}"`}
                    className={styles.blogBtmTag}
                    key={index}
                  >
                    <div className={'heroBlogTag'} key={index}>
                      {tag}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Layout location={location} renderOutsideContainer={renderOutsideContainer}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        url={post.fields.slug}
        image={post.frontmatter.featuredImage.childImageSharp.fixed.src}
        ogImage={post.frontmatter.featuredImage.childImageSharp.fixed.src}
        keywords={postKeywords}
      />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        keywords
        date(formatString: "MMMM Do, YYYY")
        description
        tags
        heroTags
        readingTime
        featuredImage {
          childImageSharp {
            fixed(width: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
