import React, { Component } from "react";

class ExternalLink extends Component {
  render() {
    return (
      <a
        href={this.props.link || this.props.href}
        target={"_blank"}
        rel={"noopener noreferrer"}
        title={this.props.title}
      >
        {this.props.children}
      </a>
    );
  }
}

export default ExternalLink;
